import { Storage } from '@capacitor/storage';
import { format as formatDate, formatDistance, formatDuration, intervalToDuration, parse as parseDate } from 'date-fns';
import { enGB, enUS, it } from 'date-fns/locale';
import DOMPurify from 'dompurify';
import { backOff } from 'exponential-backoff';
import { saveAs } from 'file-saver';
import firebase from 'firebase/app';
import ISO6391 from 'iso-639-1';
import { iso6392BTo1, iso6392TTo1 } from 'iso-639-2';
// @ts-ignore
import shaka from 'shaka-player';
import { functions } from '../../firebase';

/**
 * {@link https://getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints}
 */
export const Breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const DRM = {
  PLAYREADY: 'PlayReady',
  WIDEVINE: 'Widevine',
  FAIRPLAY: 'PN9XMURESHkO4dvf', //'FairPlay',
};

export enum CloudFunctions {
  ActyWebhook = 'http-actyWebhook',
  ActyReservation = 'http-actyReservationWebhook',
  AssignTicket = 'http-assignTicket',
  BuyShop = 'http-buyShop',
  ClearTvLoginCode = 'http-clearTvLoginCode',
  ConsumeTvLoginCode = 'http-consumeTvLoginCode',
  CreateOneOffSession = 'http-createOneOffSession',
  CreatePortalLink = 'ext-firestore-stripe-subscriptions-createPortalLink',
  GenerateCertification = 'http-generateCertification',
  GenerateCertificationStream = 'http-generateCertificationStream',
  GetAdventCalendar = 'http-getAdventCalendar',
  GetAwsGuid = 'http-getAwsGuid',
  GetBillboard = 'http-getBillboard',
  GetCheckoutDetails = 'http-getCheckoutDetails',
  GetComingSoon = 'http-getComingSoon',
  GetCompanies = 'http-getCompanies',
  GetCompanyUsers = 'http-getCompanyUsers',
  UpdateCompany = 'http-updateCompany',
  GetContent = 'http-getContent',
  GetCourse = 'http-getCourse',
  GetCourses = 'http-getCourses',
  UpdateCourse = 'http-updateCourse',
  RequestCourseContent = 'http-requestCourseContent',
  GetActyTutorCategories = 'http-getActyTutorCategories',
  GetActyTutors = 'http-getActyTutors',
  GetActyAppointments = 'http-getActyAppointments',
  GetActyAppointment = 'http-getActyAppointment',
  GetActyReservationUrl = 'http-getActyReservationUrl',
  GetCustomerData = 'http-getCustomerData',
  GetEbooks = 'http-getEbooks',
  GetEbookDownload = 'http-getEbookDownload',
  GetExperiments = 'http-getExperiments',
  GetFeedbacks = 'http-getFeedbacks',
  GetHubspotIdentificationToken = 'http-getHubspotIdentificationToken',
  GetKeepWatching = 'http-getKeepWatching',
  GetLegal = 'http-getLegal',
  GetActyFaq = 'http-getActyFaq',
  GetLogs = 'http-getLogs',
  GetMostViews = 'http-getMostViews',
  GetNextQuestion = 'http-getNextQuestion',
  GetNewVideos = 'http-getNewVideos',
  GetPreVideoData = 'http-getPreVideoData',
  GetPublishData = 'http-getPublishData',
  // GetSeries = 'http-getSeries',
  GetStaticData = 'http-getStaticData',
  GetStats = 'http-getStats',
  GetStreamingData = 'http-getStreamingData',
  GetStripeCoupons = 'http-getStripeCoupons',
  GetStripeDashboard = 'http-getStripeDashboard',
  GetStripeOneOffs = 'http-getStripeOneOffs',
  GetTicket = 'http-getTicket',
  GetTicketStatuses = 'http-getTicketStatuses',
  GetTickets = 'http-getTickets',
  GetTvLoginCode = 'http-getTvLoginCode',
  GetUserCourse = 'http-getUserCourse',
  GetUserNotifications = 'http-getUserNotifications',
  GetUserData = 'http-getUserData',
  GetVideo = 'http-getVideo',
  GetVideoReports = 'http-getVideoReports',
  // GetVideos = 'http-getVideos',
  GetVideosForTag = 'http-getVideosForTag',
  GetVideosNew = 'http-getVideosNew',
  GoogleDrive = 'http-gdrive',
  HandleCredit = 'http-handleCredit',
  HubspotWrapper = 'http-hubspotWrapper',
  ListUsers = 'http-listUsers',
  LogoutAll = 'http-logoutAll',
  ManageCache = 'http-manageCache',
  NotifyAdventData = 'http-notifyAdventData',
  QueryVideos = 'http-queryVideos',
  RateTicket = 'http-rateTicket',
  Recommendations = 'recsys-inference',
  Confidence = 'recsys-normalization',
  RequestCollaboration = 'http-requestCollaboration',
  RequestImpersonationToken = 'http-requestImpersonationToken',
  RequestVideoContent = 'http-requestVideoContent',
  RequestTicketRating = 'http-requestTicketRating',
  Reservation = 'http-reservation',
  // StripeWebhook = 'http-stripeWebhook',
  SendVideoNotifications = 'http-sendVideoNotifications',
  SendNotification = 'http-sendNotification',
  SubmitTicket = 'http-submitTicket',
  TrackConversion = 'http-tc',
  // UpdateAdminVersion = 'http-updateAdminVersion',
  UnlockAdventDay = 'http-unlockAdventDay',
  UnlockEbook = 'http-unlockEbook',
  UpdateCustomerSubscription = 'http-updateCustomerSubscription',
  UpdateHubspotProperties = 'http-updateHubspotProperties',
  UpdateUser = 'http-updateUser',
  UpsertHubspotProduct = 'http-upsertHubspotProduct',
  ValidateTest = 'http-validateTest',
  VttHandler = 'http-vttHandler',
}

export enum Experiments {
  InfiniteScroll = 'infiniteScroll',
  Recommendations = 'recommendations',
  Gaming = 'gaming',
  Library = 'library',
  Shop = 'shop',
  Live = 'live',
  Blog = 'blog',
}

export const MILLIS = (n: number): number => n;
export const SECONDS = (n: number): number => 1000 * MILLIS(n);
export const MINUTES = (n: number): number => 60 * SECONDS(n);
export const HOURS = (n: number): number => 60 * MINUTES(n);

/**
 * Consente di utilizzare una stringa HTML come contenuto di un componente.
 * La stringa viene automaticamente sanitizzata da DOMPurify.
 *
 * Attenzione: il componente che utilizza questa prop ***non può*** avere children.
 * Si consiglia di utilizzare un tag self-closing come da esempio. È possibile passare
 * normalmente altre props.
 *
 * @param {string} [htmlString=''] - La stringa da sanitizzare ed utilizzare come HTML
 * @return {object} - Un oggetto JSX da uilizzare come prop (con spread)
 * @example
 * <Component {...innerHtml(string)} />
 */
export const innerHtml = (htmlString = '') => ({
  dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(htmlString, { ADD_TAGS: ['iframe'], ADD_ATTR: ['target'] }) as string },
});

/**
 * Calcola lo SHA-256 di un determinato input.
 *
 * @async
 * @param {string} string - La stringa di cui calcolare l'hash
 * @return {Promise<string>} - L'hash della stringa
 */
export const hash = async (string: string): Promise<string> => {
  const msgUint8 = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
};

/**
 * Mappa un numero con limiti conosciuti (`start1`, `end1`) ad un altro range con limiti conosciuti (`start2`, `end2`).
 *
 * @param {number} number - Il numero da mappare
 * @param {number} start1 - Il limite inferiore del numero
 * @param {number} end1 - Il limite superiore del numero
 * @param {number} start2 - Il limite inferiore del nuovo range
 * @param {number} end2 - Il limite superiore del nuovo range
 * @return {number} - Il numero mappato al nuovo range
 */
export const map = (number: number, start1: number, end1: number, start2: number, end2: number): number => {
  return ((number - start1) / (end1 - start1)) * (end2 - start2) + start2;
};

/**
 * Limita un numero ad un determinato range.
 *
 * @param {number} number - Il numero da limitare
 * @param {number} start - Il limite inferiore che può avere il numero
 * @param {number} stop - Il limite superiore che può avere il numero
 * @return {number} - Il numero limitato
 */
export const clamp = (number: number, start: number, stop: number): number => {
  return number < start ? start : number > stop ? stop : number;
};

/**
 * Dato un numero in millisecondi, restituisce una durata nel formato HH:mm:ss
 *
 * @param {number} millis - Il numero di millisecondi
 * @return {string} - La durata formattata
 */
export const parseDuration = (millis: number): string => {
  const d = new Date(1000 * Math.round(millis / 1000));
  const pad = (i: number) => ('0' + i).slice(-2);
  return d.getUTCHours() + ':' + pad(d.getUTCMinutes()) + ':' + pad(d.getUTCSeconds());
};

/**
 * Genera un colore casuale sulla base di una stringa generica.
 *
 * La stessa stringa genererà sempre lo stesso colore.
 *
 * @param {string} string - La stringa da usare come seed
 * @return {string} - Un colore in formato HEX (#RRGGBB)
 */
export const generateColorFromArbitraryString = (string: string): string => {
  function hashCode(str: string): number {
    if (!str) {
      return 0;
    }
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  const hashedString = hashCode(string);
  const c = (hashedString & 0x00ffffff).toString(16).toUpperCase();

  return '#' + ('00000'.substring(0, 6 - c.length) + c);
};

/**
 * Ordina un array sulla base della funzione di comparazione passata in input.
 *
 * Il sort è stabile, nel senso che se due elementi risultano uguali per la funzione di comparazione
 * questi manterranno lo stesso ordine nell'array finale.
 *
 * L'array originario non viene modificato.
 *
 * @param {Array<*>} array - L'array da ordinare
 * @param {function(*,*):number} compare - La funzione di comparazione
 * @return {Array<*>} - Un nuovo array ordinato
 */
export const stableSort = <T>(array: T[], compare: (t1: T, t2: T) => number): T[] => {
  return array
    .map((item, index) => ({ item, index }))
    .sort((a, b) => compare(a.item, b.item) || a.index - b.index)
    .map(({ item }) => item);
};

/**
 * Formatta un numero di secondi in una data, secondo un determinato formato
 *
 * @param {number} secondsTimestamp - Il numero di secondi da formattare
 * @param {string} [format='dd MMMM Y'] - Il formato da utilizzare
 * @param {Locale} [locale=it] - La lingua da utilizzare
 * @return {string} - La data formattata
 */
export const formatSecondsTimestamp = (secondsTimestamp: number, format = 'dd MMMM Y', locale = it): string => {
  return formatDate(new Date(secondsTimestamp * 1000), format, { locale });
};

/**
 * Formatta un numero di secondi in un formato leggibile, ad esempio "1 ora 25 minuti 36 secondi".
 *
 * @param {number} secondsDuration - Il numero di secondi da formattare
 * @param {Locale} [locale=enGB] - La lingua da utilizzare
 * @return {string} - La durata formattata
 */
export const formatSecondsDuration = (secondsDuration: number, locale = enGB): string => {
  try {
    return formatDuration(intervalToDuration({ start: 0, end: secondsDuration * 1000 }), { locale });
  } catch (error) {
    console.error(error);
    return '' + secondsDuration;
  }
};

/**
 * Formatta un numero di secondi in un formato leggibile, ad esempio "un'ora e 25 minuti" o "circa 3 ore", con arrotondamento.
 *
 * @param {number} secondsDuration - Il numero di secondi da formattare
 * @param {Locale} [locale=enGB] - La lingua da utilizzare
 * @return {string} - La durata formattata
 */
export const humanizeSecondsDuration = (secondsDuration: number, locale = enGB): string => {
  return formatDistance(0, secondsDuration, { locale });
};

/**
 * Formatta una data in stringa in un determinato formato in un altro.
 *
 * @param {string} string - La data in stringa da riformattare
 * @param {string} inputFormat - Il formato della data in input
 * @param {string} outputFormat - Il formato della data in output
 */
export const formatStringDate = (string: string, inputFormat: string, outputFormat: string): string => {
  try {
    return formatDate(parseDate(string, inputFormat, new Date(), { locale: enUS }), outputFormat, { locale: it });
  } catch {
    return string;
  }
};

/**
 * Restituisce se un determinato Video Element è attualmente in riproduzione.
 *
 * @param {HTMLVideoElement} video - Il Video Element
 * @return {boolean} - Boolean che rappresenta se il Video element è in riproduzione
 */
export const isPlaying = (video: HTMLVideoElement): boolean => {
  try {
    return !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
  } catch {
    return false;
  }
};

/**
 * Ottiene i cookie del document in un comodo oggetto.
 *
 * @return {object} - L'oggetto contenente i cookie
 */
export const readCookies = (): { [p: string]: string } => {
  return document.cookie
    .split('; ')
    .map((cookie) => cookie.split('='))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
};

/**
 * Imposta un cookie sul document.
 *
 * @param {string} name - Il nome del cookie
 * @param {string} value - Il valore del cookie
 */
export const setCookie = (name: string, value: string) => {
  document.cookie = `${name}=${value};`;
};

/**
 * Elimina un cookie dal document.
 *
 * @param {string} name - Il nome del cookie da eliminare
 */
export const deleteCookie = (name: string) => {
  document.cookie = `${name}=;expires=${new Date(0).toUTCString()}`;
};

/**
 * Genera un identificativo di 32 caratteri e lo salva sullo storage corretto a seconda del dispositivo.
 *
 * Se un token è già esistente, questo viene restituito senza generarne uno nuovo.
 *
 * @async
 * @return {Promise<string>} - Il token generato
 */
export const calculateDeviceIdentifier = async (): Promise<string> => {
  const { value } = await Storage.get({ key: 'tootorDeviceIdentifier' });
  if (value) return value;
  const generatedDeviceIdentifier = ((): string => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return Array(32)
      .fill('')
      .map(() => charset[Math.floor(Math.random() * charset.length)])
      .join('');
  })();
  await Storage.set({ key: 'tootorDeviceIdentifier', value: generatedDeviceIdentifier });
  return generatedDeviceIdentifier;
};

/**
 * Salva un file sul dispositivo, in base agli header restituiti dal server.
 *
 * @param {Response} response - La response del server
 */
export const download = async (response: Response) => {
  const cdHeader = response.headers.get('Content-Disposition');
  if (!cdHeader) return;
  const [, filename] = cdHeader.match(/filename="(.+)"/);
  const blob = await response.blob();
  saveAs(blob, filename);
};

export const fetchAndSave = async (url: string, filename: string) => {
  const blob = await fetch(url).then((response) => response.blob());
  saveAs(blob, filename);
};

/**
 * Ottiene un reducer da utilizzare con `useReducer()` per gestire un form.
 */
export const formReducer = (state: { [key: string]: any }, event: { name: string; value: any }) => {
  if (event.name == 'RESET') return { ...event.value };
  return { ...state, [event.name]: event.value };
};

/**
 * Ottiene un numero intero casuale compreso tra `min` e `max`.
 *
 * @param {number} min - Il numero minimo
 * @param {number} max - Il numero massimo
 * @return {number} - Il numero casuale
 */
export const randomBetween = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * Esegue una funzione ogni tot secondi, dove tot è un numero casuale compreso tra `min` e `max`.
 *
 * @param {function} intervalFn - La funzione da eseguire
 * @param {number} min - Il numero minimo di secondi tra un'invocazione e la successiva
 * @param {number} max - Il numero massimo di secondi tra un'invocazione e la successiva
 * @return {object} - Un oggetto con metodo `clear()` per interrompere le esecuzioni successive
 */
export const setRandomInterval = (intervalFn: () => void, min: number, max: number) => {
  let timeout;

  const runInterval = () => {
    const timeoutFunction = () => {
      intervalFn();
      runInterval();
    };

    const delay = randomBetween(min, max);
    timeout = setTimeout(timeoutFunction, delay);
  };

  runInterval();

  return {
    clear() {
      clearTimeout(timeout);
    },
  };
};

/**
 * Dato l'oggetto `userDataFmt` restituisce un boolean che rappresenta se l'utente deve completare o meno il primo accesso.
 *
 * @param {object} userDataFmt - L'oggetto `userDataFmt`
 * @return {boolean} - Boolean che rappresenta se l'utente deve completare il primo accesso
 */
export const shouldCompleteFirstLogin = (userDataFmt) => {
  return !userDataFmt.profile || !userDataFmt.profile.formData || Object.keys(userDataFmt.profile.formData).length <= 1;
};

/**
 * Dato un oggetto, ottiene una stringa da utilizzare come query params.
 *
 * La stringa conterrà una chiave 'token', valorizzata con un hash degli altri parametri, così da poterne garantire l'integrità.
 *
 * @async
 * @param {object} object - Oggetto contenente le coppie chiave-valore da utilizzare come query params
 * @return {Promise<string>} - La stringa da usare come query params
 */
export const createHashedQueryParams = async (object: { [p: string]: string }): Promise<string> => {
  if (!Object.keys(object).length) return '';
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(object)) {
    if (key != 'token') params.set(key, value);
  }
  const token = await hash(params.toString());
  params.set('token', token);
  return params.toString();
};

/**
 * Dato un oggetto, verifica che sia presente l'hash e che corrisponda con il resto dei parametri per garantirne l'integrità.
 *
 * È possibile passare una lista proprietà che devono essere presenti, pena l'invalidità dell'oggetto anche se l'hash è corretto.
 *
 * @async
 * @param {object} object - Oggetto contenente le coppie chiave-valore per cui ne va verificato il token
 * @param {Array<string>} [mandatoryKeys=[]] - Lista di chiavi la cui presenza è obbligatoria
 */
export const verifyHashedQueryParams = async (object: { [p: string]: string }, mandatoryKeys: string[] = []): Promise<boolean> => {
  if (!object) return false;
  if (!Object.keys(object).length) return false;
  if (!object.token) return false;
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(object)) {
    if (key != 'token') params.set(key, value);
  }
  for (const mandatoryKey of mandatoryKeys) {
    if (params.get(mandatoryKey) == null) return false;
  }
  const token = await hash(params.toString());
  return token == object.token;
};

export type FileWithUuid = File & { uuid: string };
/**
 * @wip
 */
export const storageUploadTask = (options: {
  item: File | FileWithUuid;
  storage: firebase.storage.Reference;
  path: string;
  currentUser: firebase.User;
  onProgress: (progress: number) => void;
}): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const storageRef = options.storage.child(options.path);
    const uploadTask = storageRef.put(options.item, {
      customMetadata: { uid: options.currentUser.uid, originalFileName: options.item.name, contentType: options.item.type },
    });
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100) | 0;
        options.onProgress(progress);
      },
      (error) => {
        console.error(error);
        reject(`${error.code}: ${error.message}`);
      },
      () => {
        resolve();
      }
    );
  });
};

/**
 * Ottiene un possibile DRM supportato dal sistema.
 *
 * @async
 * @return {Promise<string>} - Il DRM supportato
 */
export const checkSupportedDRM = (): Promise<string> => {
  if (!navigator.requestMediaKeySystemAccess) {
    return Promise.resolve(DRM.FAIRPLAY);
  }

  const configCENC = [
    {
      initDataTypes: ['cenc'],
      audioCapabilities: [
        {
          contentType: 'audio/mp4;codecs="mp4a.40.2"',
        },
      ],
      videoCapabilities: [
        {
          contentType: 'video/mp4;codecs="avc1.42E01E"',
        },
      ],
    },
  ];
  const requestKey = (key: string) => navigator.requestMediaKeySystemAccess(key, configCENC);

  return new Promise((resolve) => {
    requestKey('com.widevine.alpha')
      .then((_) => resolve(DRM.WIDEVINE))
      .catch(() => {
        requestKey('com.microsoft.playready')
          .then((_) => resolve(DRM.PLAYREADY))
          .catch(() => resolve(DRM.FAIRPLAY));
      });
  });
};

/**
 * Scambia due elementi all'interno di un array, senza modificare l'array originario.
 *
 * @param {Array<*>} array - L'array da modificare
 * @param {number} fromIndex - L'indice di partenza
 * @param {number} toIndex - L'indice di destinazione
 * @return {Array<*>} - Un nuovo array con gli elementi scambiati
 */
export function arrayMoveImmutable<T>(array: T[], fromIndex: number, toIndex: number): T[] {
  array = [...array];
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
  return array;
}

/**
 * Ottiene una traduzione italiana dell'intervallo di tempo di un abbonamento (mese, anno).
 *
 * Passare `true` per ottenere l'avverbio invece del sostantivo.
 *
 * @param {string} interval - L'intervallo da tradurre
 * @param {boolean} [ly=false] - Preferenza se ottenere l'avverbio o il sostantivo
 * @return {string} - La traduzione dell'intervallo
 */
export const getPriceInterval = (interval: string, ly = false): string => {
  switch (interval) {
    case 'month':
      return ly ? 'mensile' : 'mese';
    case 'year':
      return ly ? 'annuale' : 'anno';
  }
};

/**
 * Crea un'istanza di uno Shaka Player, comprensiva della gestione del DRM.
 *
 * @param {HTMLVideoElement} videoElement - Il Video Element che andrà ad essere wrappato
 * @param {object} video - L'entità `video` di Tootor
 * @param {object} drm - Il DRM supportato
 * @param {boolean} handleSubs - Preferenza di gestione dei sottotitoli
 * @return {shaka.Player} - Lo Shaka Player
 */
export const createShakaPlayer = (videoElement: HTMLVideoElement, video: any, drm: string, handleSubs: boolean): shaka.Player => {
  const LICENSE_URI = 'https://license-global.pallycon.com/ri/licenseManager.do';

  const shakaPlayer: shaka.Player = new shaka.Player(videoElement);
  shakaPlayer.addEventListener('error', console.error);
  shakaPlayer.configure({
    drm: {
      servers: {
        'com.widevine.alpha': LICENSE_URI,
        'com.microsoft.playready': LICENSE_URI,
      },
    },
  });

  const handleSubsFn = () => {
    if (video.subs && video.subs.length) {
      for (const { url, languageCode, label } of video.subs) {
        shakaPlayer
          .addTextTrackAsync(url, languageCode, 'subtitles', null, null, label)
          .then((track) => {
            console.log('loaded: %s', track.label);
            // shakaPlayer.selectTextTrack(track);
          })
          .catch(console.error);
      }
    }
  };

  const promise = (() => {
    if (process.env.NODE_ENV != 'production') {
      // return shakaPlayer.load('https://storage.googleapis.com/shaka-demo-assets/angel-one/dash.mpd');
      //
      // i18nout
      // return shakaPlayer.load(
      //   'https://d20cuzlqi8c790.cloudfront.net/out/v1/b7f3ecb8877842238c1d6a87ea1fe3be/86ae4776e24a486db2a479ce72e9a887/97780156eb4b4f06bfcc69f482301b81/index.mpd'
      // );
    }

    // https://github.com/inka-pallycon/html5-player-drm-samples/blob/main/js/shaka-sample.js
    if ([DRM.WIDEVINE, DRM.PLAYREADY].includes(drm)) {
      shakaPlayer.getNetworkingEngine().registerRequestFilter((type, request) => {
        if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
          request.headers['pallycon-customdata-v2'] = video.drmToken;
        }
      });
      return shakaPlayer.load(video.dash);
    } else {
      return shakaPlayer.load(video.hls);
    }
  })();

  promise
    .then(() => {
      if (handleSubs) handleSubsFn();
    })
    .catch(console.error);

  return shakaPlayer;
};

/**
 * Ottiene il base URL per invocare manualmente una Cloud Function.
 *
 * @return {string} - Il base URL
 */
export const getFunctionsBaseUrl = (): string => {
  if (process.env.NODE_ENV != 'production') return 'http://localhost:5001/tootor-development/europe-west3';
  if (window.location.href.includes('tootor-dev')) return 'https://europe-west3-tootor-development.cloudfunctions.net';
  return 'https://europe-west3-tootor-production.cloudfunctions.net';
};

/**
 * Ottiene l'URL di una determinata Cloud Function.
 *
 * @param {CloudFunctions} path - La Cloud Function di cui ottenere l'URL
 * @return {string} - L'URL della Cloud Function
 */
export const getFunctionsUrl = (path: CloudFunctions): string => {
  const baseUrl = getFunctionsBaseUrl();
  return `${baseUrl}/${path}`;
};

/**
 * Invoca una Cloud Function.
 *
 * @param {CloudFunctions} name - La Cloud Function da invocare
 * @param {object} [data={}] - L'oggetto da passare come body alla Cloud Function
 * @param {object} [options={}] - Opzioni di personalizzazione dell'invocazione
 * @param {boolean} [options.useExponentialBackoff=true] - Specifica se utilizzare l'exponential backoff
 */
export const fn = async (name: CloudFunctions, data: any = {}, { useExponentialBackoff = true } = {}) => {
  const extract = (result: any) => {
    try {
      return result.data;
    } catch {
      return result;
    }
  };

  const callable = functions.httpsCallable(name);

  if (!useExponentialBackoff) {
    const result = await callable(data);
    return extract(result);
  }

  // https://www.npmjs.com/package/exponential-backoff
  const result = await backOff(() => callable(data), {
    jitter: 'full',
    retry: (error) => {
      console.error(error);
      return true;
    },
  });
  return extract(result);
};

/**
 * Aggiunge un evento per Google Tag Manager.
 *
 * @param {object} event - L'evento da aggiungere
 * @param {string} event.event - Il nome dell'evento
 */
export const gtag = (event: { event: string; [p: string]: any }) => {
  (window as any).dataLayer.push(event);
};

/**
 * Dato un codice ISO-639-1 restituisce il nome della lingua.
 *
 * @param {string} languageCode - Codice ISO-639-1 della lingua
 * @return {string} - Il nome della lingua (nella lingua stessa)
 * @example
 * getLanguageName('it'); // Italiano
 * getLanguageName('de'); // Deutsch
 */
export const getLanguageName = (languageCode: string): string => ISO6391.getNativeName(languageCode);

/**
 * Dato un codice ISO-639-2 restituisce il corrispettivo ISO-639-1
 *
 * @param {string} languageCodeIso2 - Codice ISO-639-2 della lingua
 * @return {string} - Il codice ISO-639-1
 * @example
 * iso2to1('ita'); // it
 * iso2to1('spa'); // es
 */
export const iso2to1 = (languageCodeIso2: string): string => {
  languageCodeIso2 = languageCodeIso2.toLowerCase();
  return (iso6392BTo1[languageCodeIso2] || iso6392TTo1[languageCodeIso2])?.toLowerCase();
};

/**
 * Il country code è italiano?
 *
 * @param {string} countryCode - Il country code...
 * @return {boolean} - ...è italiano?
 */
export const isItalian = (countryCode: string) => {
  return countryCode == 'IT';
};

interface FastNetOptions {
  timesToTest: number;
  threshold: number;
  image: string;
  allowEarlyExit: boolean;
  verbose: boolean;
}

/**
 * {@link https://www.npmjs.com/package/isfastnet}
 */
export const isFastNet = (callback, options: Partial<FastNetOptions> = {}) => {
  const _options: FastNetOptions = {
    timesToTest: 5,
    threshold: 200,
    image: 'https://www.google.com/images/phd/px.gif',
    allowEarlyExit: true,
    verbose: false,
  };

  Object.assign(_options, options);

  const arrTimes = [];
  let i = 0;
  const dummyImage = new Image();
  let isDismissed = false;

  if (_options.verbose) {
    _options.allowEarlyExit = false;
  }

  // Recursively get average latency
  function testLatency(cb) {
    if (_options.allowEarlyExit) {
      setTimeout(() => {
        if (i === 0) {
          i = _options.timesToTest;
          isDismissed = true;
          cb(_options.threshold * 3 + 1);
        }
      }, _options.threshold * 3);
    }

    const startTime = new Date().getTime();
    if (i < _options.timesToTest - 1) {
      dummyImage.src = `${_options.image}?t=${startTime}`;
      dummyImage.onload = () => {
        const endTime = new Date().getTime();
        const timeTaken = endTime - startTime;
        arrTimes[i] = timeTaken;
        testLatency(cb);
        i += 1;
      };
    } else {
      /** calculate average */
      const sum = arrTimes.reduce((a, b) => a + b);
      const avg = sum / arrTimes.length;
      if (!isDismissed) {
        if (_options.verbose) {
          const objectToReturn = {
            averageLatency: avg,
            threshold: _options.threshold,
            latencyReadings: arrTimes,
          };
          cb(objectToReturn);
        } else {
          cb(avg);
        }
      }
    }
  }

  testLatency((avgInfo) => {
    if (_options.verbose) {
      avgInfo.isFast = avgInfo.averageLatency <= _options.threshold;
      callback(avgInfo);
    } else {
      callback(avgInfo <= _options.threshold);
    }
  });
};

/**
 * {@link https://stackoverflow.com/a/54631141/5110346}
 */
const probeWebpSupport = (feature: 'lossy' | 'lossless' | 'alpha' | 'animation'): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    const testImages = {
      lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
      lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
      alpha: 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
      animation: 'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
    };
    const img = new Image();
    img.onload = () => {
      const result = img.width > 0 && img.height > 0;
      resolve(result);
    };
    img.onerror = () => {
      resolve(false);
    };
    img.src = 'data:image/webp;base64,' + testImages[feature];
  });
};

/**
 * Dato un URL ad un manifest MSS, restituisce la durata del video.
 *
 * @async
 * @param {string} mssUrl - URL al manifest MSS
 * @return {Promise<number>} - La durata del video
 */
export const getVideoDuration = async (mssUrl: string): Promise<number> => {
  try {
    const mssResponse = await fetch(mssUrl);
    const mssXml = await mssResponse.text();
    const [, fullDuration] = mssXml.match(/Duration="(\d+)"/);
    return +fullDuration.slice(0, fullDuration.length - 4);
  } catch {
    return null;
  }
};

export const trackVideoRecommendation = async (
  database: firebase.firestore.Firestore,
  fv: typeof firebase.firestore.FieldValue,
  currentUser: firebase.User,
  video: any
) => {
  if (!video.confidence) return Promise.resolve();
  try {
    const object = {
      created: fv.serverTimestamp(),
      index: video.index || null,
      confidence: video.confidence || null,
      seriesId: video.seriesId || null,
      recommended: !!video.recommended,
    };
    const newDocument = await database.collection(`recsys/tracking/videos/${video.id}/users/${currentUser.uid}/clicks`).add(object);
    await database.doc(`recsys/tracking/users/${currentUser.uid}/videos/${video.id}/clicks/${newDocument.id}`).set(object);
  } catch (error) {
    console.error(error);
  }
  return Promise.resolve();
};

export const addConfidence =
  (videos, seriesTags, mostViews, series, recommended: boolean) =>
  ({ videoId, seriesId, confidence }, index) => {
    const videoMatch = videos.find((v) => v.id == videoId);
    if (!videoMatch) return null;
    videoMatch.seriesId = seriesId;
    videoMatch.confidence = confidence;
    videoMatch.index = index;
    videoMatch.recommended = recommended;

    (videoMatch.tags || []).forEach(({ id }) => {
      const serieTag = seriesTags.find((s) => s.tag.id == id);
      if (!serieTag) return;
      const videoUpdate = serieTag.tagVideos.find((v) => v.id == videoId);
      if (!videoUpdate) return;
      videoUpdate.seriesId = seriesId;
      videoUpdate.confidence = confidence;
      videoUpdate.index = index;
      videoUpdate.recommended = recommended;
    });

    const videoInMostViews = mostViews.find((v) => v.id == videoId);
    if (videoInMostViews) {
      videoInMostViews.seriesId = seriesId;
      videoInMostViews.confidence = confidence;
      videoInMostViews.index = index;
      videoInMostViews.recommended = recommended;
    }

    const seriesPartOf = series.filter((serie) => serie.seasons.find((season) => season.episodes.find((episode) => episode.video.id == videoId)));
    seriesPartOf.forEach((serie) => {
      serie.seasons.forEach((season) => {
        const episodesToUpdate = season.episodes.filter((e) => e.video.id == videoId);
        episodesToUpdate.forEach((episode) => {
          episode.video.seriesId = seriesId;
          episode.video.confidence = confidence;
          episode.video.index = index;
          episode.video.recommended = recommended;
        });
      });
    });

    return videoMatch;
  };

export const clearWatchingInfo = async (currentUserId: string, realtimeDatabase: firebase.database.Database, clearAll: boolean = false) => {
  const rtdbUserRecord = realtimeDatabase.ref(`users/${currentUserId}`);
  const clientName = `client_${await calculateDeviceIdentifier()}`;
  // Se clearAll è true significa che si sta facendo un logout da tutti i dispositivi e quindi cancelliamo tutti i client dal realtime database
  if (clearAll) {
    const onValueCallback = async (snapshot: firebase.database.DataSnapshot) => {
      const data = snapshot.val() || {};
      for (const key of Object.keys(data)) {
        if (key.includes('client_') && (data[key].online === 0 || key === clientName)) {
          await rtdbUserRecord.update({
            [key]: null,
          });
        }
      }
      realtimeDatabase.ref(`users/${currentUserId}`).onDisconnect().cancel();
      realtimeDatabase.goOffline();
    };
    rtdbUserRecord.once('value', onValueCallback).catch(console.error);
  } else {
    // Se clearAll è false significa che si sta facendo un logout da 1 dispositivo e quindi cancelliamo il client dal realtime database
    await rtdbUserRecord.update({
      [clientName]: null,
    });
    realtimeDatabase.ref(`users/${currentUserId}/${clientName}`).onDisconnect().cancel();
    realtimeDatabase.goOffline();
  }
};

export const manualCloudFunction = (name: CloudFunctions, currentUser: firebase.User, body: { [p: string]: any }) => {
  return currentUser
    .getIdToken(true)
    .then((token) => {
      const baseUrl =
        window.location.origin.includes('tootor-dev') || process.env.NODE_ENV == 'development'
          ? 'europe-west3-tootor-development'
          : 'europe-west3-tootor-production';
      return fetch(`https://${baseUrl}.cloudfunctions.net/${name}`, {
        method: 'POST',
        headers: { 'content-type': 'application/json', authorization: `Bearer ${token}` },
        body: JSON.stringify(body),
      });
    })
    .then((res) => res.json())
    .catch(console.error);
};

export const DEFAULT_JSON_VIEWER_VALUE = '{}';
export const stringify = (obj) => {
  try {
    const o = obj || {};

    const replacer = (key, value) =>
      value instanceof Object && !(value instanceof Array)
        ? Object.keys(value)
            .sort()
            .reduce((sorted, key) => {
              sorted[key] = value[key];
              return sorted;
            }, {})
        : value;

    delete o['lastEditBy'];
    delete o['thumbnail'];
    delete o['thumbnail_vertical'];
    delete o['sm_thumbnail'];
    delete o['sm_thumbnail_vertical'];
    delete o['trailer'];
    delete o['subs'];
    delete o['logo'];
    if (o['author']) delete o['author']['photo'];
    if (o['xray']) for (const xray of o['xray']) delete xray['image'];

    return JSON.stringify(o, replacer, 2);
  } catch {
    return DEFAULT_JSON_VIEWER_VALUE;
  }
};

export const canAccessFeature = (feature: 'SHOP' | 'BLOG' | 'LIVE' | 'BOOKS', currentLanguage: string, staticData: any, userDataFmt: any) => {
  const experiment = (() => {
    switch (feature) {
      case 'SHOP':
        return Experiments.Shop;
      case 'BLOG':
        return Experiments.Blog;
      case 'LIVE':
        return Experiments.Live;
      case 'BOOKS':
        return Experiments.Library;
      default:
        return null;
    }
  })();
  if (experiment && userDataFmt?.experiments?.[experiment]) return true;
  if (!staticData[`SHOW_${feature}`]) return false;
  if (!staticData[`SHOW_${feature}_FOR_LANGS`]?.includes(currentLanguage)) return false;
  if (!staticData[`SHOW_${feature}_FOR_CATEGORIA_PROFESSIONALE`]?.includes(userDataFmt?.profile?.formData?.categoriaProfessionale)) return false;
  return true;
};

/**
 * Given an image returns the same image in base64.
 * @param {File} file
 * @param {boolean} rawBase64
 * @returns encoded image in base64
 */
export const fileToDataUrl = (file: File | Blob, rawBase64: boolean = false): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!file) {
      resolve(null);
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const encoded = String(reader.result);
      if (rawBase64) {
        let raw = encoded.replace(/^data:(.*;base64,)?/, '');
        if (raw.length % 4 > 0) {
          raw += '='.repeat(4 - (raw.length % 4));
        }
        resolve(raw);
        return;
      }
      resolve(encoded);
    };
    reader.onerror = reject;
  });
};

export const isValidPhoneNumber = (phoneNumber) => {
  return /^\+\d{2,3}\d{7,}$/.test(phoneNumber);
};
