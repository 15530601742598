import {
  faAd,
  faAward,
  faBookMedical,
  faExternalLinkAlt,
  faFolderOpen,
  faInfoCircle,
  faLightbulb,
  faLock,
  faPause,
  faPlay,
  faPlayCircle,
  faTags,
  faTimes,
  faVolumeMute,
  faVolumeUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Button, ButtonGroup, ButtonToolbar, ListGroup, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFirebase } from '../../../contexts/FirebaseContext';
import './VideoCard.scss';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { VideoRating } from '../VideoRating/VideoRating';
import { MyListButton } from '../MyListButton/MyListButton';
import {
  addConfidence,
  CloudFunctions,
  Experiments,
  formatSecondsTimestamp,
  manualCloudFunction,
  parseDuration,
  trackVideoRecommendation,
} from '../../utils/utils';
import { useMobileDetect } from '../../../hooks/useMobileDetect';
import { useCapacitor } from '../../../hooks/useCapacitor';
import { Video } from '../Video/Video';
import { useTranslation } from 'react-i18next';
import { Blurhash } from 'react-blurhash';
import { SubscribeButton } from '../SubscribeButton/SubscribeButton';
import { CountryFlag } from '../CountryFlag/CountryFlag';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';
import { useDetectChanges } from '../../../hooks/useDetectChanges';
import { useGaming } from '../../../contexts/GamingContext';
import { isWithinInterval } from 'date-fns';

const VideoCardComponent = ({
  empty = false,
  video = {} as any,
  keepWatching = false,
  onDetailsOpen = () => {},
  onDetailsClose = () => {},
  onThumbnailLoad = (id) => {},
  onKeepWatchingRemove = (id) => {},
  forceVertical = false,
  showPosition = false,
  className = '',
  innerRef = null,
  hidden = false,
  index = null,
}) => {
  const { t } = useTranslation();
  const { currentLanguage } = useCurrentLanguage();
  const { series, database, currentUser, staticData, updateUserData, subscription, userDataFmt, isVideoLocked, fv, videos, seriesTags, mostViewsVideos } =
    useFirebase();
  const { isEnabled: isGamingEnabled } = useGaming();
  const history = useHistory();
  const { windowWidth } = useWindowSize();
  const modalVideoElementRef = useRef<HTMLVideoElement>();
  const cardRef = useRef<HTMLDivElement>();
  const [seriesPartOf, setSeriesPartOf] = useState([]);
  const [thumbnailLoading, setThumbnailLoading] = useState(true);
  const detectChanges = useDetectChanges();

  const [seriesSelectModal, setSeriesSelectModal] = useState(false);

  const { isMobile } = useMobileDetect();
  const { isNative } = useCapacitor();

  const [elementWidth, setElementWidth] = useState(0);
  const [elementHeight, setElementHeight] = useState(0);

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isAdvModalOpen, setIsAdvModalOpen] = useState(false);
  const [detailsTrailerAudio, setDetailsTrailerAudio] = useState(false);
  const [detailsTrailerPlay, setDetailsTrailerPlay] = useState(true);

  const [removeKeepWatchingLoading, setRemoveKeepWatchingLoading] = useState(false);

  const [categories, tags] = useMemo(() => {
    if (!video) return;
    if (!video.tags || !video.tags.length) return [[], []];
    const categories = video.tags.filter((tag) => tag.showAsSeries);
    const tags = video.tags.filter((tag) => !tag.showAsSeries);
    return [categories, tags];
  }, [video]);

  useEffect(() => {
    if (!video || !video.id) return;
    if (video.seriesId) {
      const _seriesPartOf = series.find((serie) => serie.id == video.seriesId);
      setSeriesPartOf([_seriesPartOf]);
    } else {
      const _seriesPartOf = series.filter((serie) => serie.seasons.find((season) => season.episodes.find((episode) => episode.video.id == video.id)));
      setSeriesPartOf(_seriesPartOf);
    }
  }, [video, series]);

  useEffect(() => {
    if (!video || !seriesPartOf || !isDetailsModalOpen || !!video.confidence) return;
    if (currentLanguage == 'it' && (staticData.ENABLE_RECOMMENDATIONS || userDataFmt.experiments?.[Experiments.Recommendations])) {
      manualCloudFunction(CloudFunctions.Confidence, currentUser, {
        id_user: currentUser.uid,
        id_videos: video.id,
        id_series: seriesPartOf.map((s) => s.id),
      }).then((result) => {
        if (!result || !result.score) return;
        addConfidence(videos, seriesTags, mostViewsVideos, series, false)({ videoId: video.id, seriesId: null, confidence: result.score }, null);
        detectChanges();
      });
    }
  }, [video, seriesPartOf, isDetailsModalOpen]);

  const setCardSizes = () => {
    const _elementWidth = cardRef.current?.clientWidth - 8;

    const vertical = () => (_elementWidth || 0) * 1.38;
    const horizontal = () => (((_elementWidth || 0) * 56.25) / 100) | 0;

    const _elementHeight = isMobile || forceVertical ? vertical() : horizontal();

    if (_elementWidth != elementWidth) setElementWidth(_elementWidth);
    if (_elementHeight != elementHeight) setElementHeight(_elementHeight);
  };

  useEffect(() => {
    if (showPosition) document.documentElement.style.setProperty('--position-number-font-size', `${elementHeight * 0.6}px`);
  }, [elementHeight]);

  useEffect(() => {
    setCardSizes();
  }, []);

  useEffect(() => {
    setTimeout(() => setCardSizes());
  }, [windowWidth, cardRef.current, thumbnailLoading]);

  useEffect(() => {
    if (isDetailsModalOpen) onDetailsOpen();
    else onDetailsClose();
  }, [isDetailsModalOpen]);

  useEffect(() => {
    if (!modalVideoElementRef.current) return;

    try {
      if (detailsTrailerPlay) modalVideoElementRef.current.play().catch(console.error);
      else modalVideoElementRef.current.pause();
    } catch {}
  }, [detailsTrailerPlay]);

  return (
    <>
      <div
        className={className}
        style={{ height: `${elementHeight}px` }}
        ref={innerRef}
        onClick={() => {
          if (!innerRef) return;
          if (!video?.title) return;
          setIsDetailsModalOpen(true);
        }}
      >
        {!hidden && (
          <>
            <div
              ref={cardRef}
              className={'scrollable-card ' + (isMobile || !video?.title ? 'disable-hover' : '')}
              style={{
                width: '100%',
                height: `${elementHeight}px`,
              }}
              onClick={() => {
                isWithinInterval(new Date(), {
                  start: new Date(staticData.VIDEO_ADV_START._seconds * 1000),
                  end: new Date(staticData.VIDEO_ADV_END._seconds * 1000),
                });
                if (!video?.title) return;
                if (!keepWatching) return setIsDetailsModalOpen(true);
                const doesAdvExist = isWithinInterval(new Date(), {
                  start: new Date(staticData.VIDEO_ADV_START._seconds * 1000),
                  end: new Date(staticData.VIDEO_ADV_END._seconds * 1000),
                });
                if (doesAdvExist && !userDataFmt.hasAlreadySeenAd) {
                  setIsAdvModalOpen(true);
                  return;
                } else {
                  trackVideoRecommendation(database, fv, currentUser, video).catch(console.error);
                  history.push(`/watch/${video.parent || video.id}`);
                }
              }}
            >
              {!!currentLanguage && currentLanguage.toLowerCase() != 'it' && !!video.languages?.includes(currentLanguage) && (
                <div className={'video-card-flag'}>
                  <CountryFlag language={currentLanguage} />
                </div>
              )}
              <div className={'relative'} style={{ width: '100%', height: `${elementHeight}px` }}>
                <div className={'card-video ' + (thumbnailLoading ? 'loading' : '')} style={{ width: '100%', height: `${elementHeight}px` }}>
                  {(((isMobile || forceVertical) && video.thumbnail_vertical_hash) || (!(isMobile || forceVertical) && video.thumbnail_hash)) &&
                    elementWidth &&
                    staticData.ENABLE_BLURHASH && (
                      <Blurhash
                        className={'video-card-blurhash-wrapper ' + (thumbnailLoading ? '' : 'video-card-blurhash-wrapper-hidden')}
                        hash={isMobile || forceVertical ? video.thumbnail_vertical_hash : video.thumbnail_hash}
                        width={elementWidth}
                        height={elementHeight}
                      />
                    )}
                  {!thumbnailLoading && isVideoLocked(video) && !video.comingSoon && !keepWatching && (
                    <FontAwesomeIcon icon={faLock} className={'video-lock-icon'} size={'2x'} />
                  )}
                  <img
                    src={isMobile || forceVertical ? video.sm_thumbnail_vertical || video.thumbnail_vertical : video.sm_thumbnail || video.thumbnail}
                    alt={video.title}
                    loading={'lazy'}
                    className={(thumbnailLoading ? 'invisible ' : '') + (isVideoLocked(video) && !video.comingSoon && !keepWatching ? 'locked' : '')}
                    onLoad={() => {
                      setThumbnailLoading(false);
                      onThumbnailLoad(video.id);
                    }}
                  />
                </div>
              </div>
              <div className={'border-box'}>{video.title}</div>
              {isMobile && keepWatching && (
                <div className={'big-play-button'}>
                  <Button variant={'outline-light'}>
                    <FontAwesomeIcon icon={faPlay} fixedWidth={true} size={'lg'} />
                  </Button>
                </div>
              )}
              {!!video.isValidBonus && isGamingEnabled && <FontAwesomeIcon className={'cockade'} icon={faAward} />}
              {!!showPosition && (
                <span className={'position-number'}>
                  <span>{index + 1}</span>
                </span>
              )}
              {/*{!!video.trailer && !keepWatching && (
            <Button className={'volume-override'} variant={'outline-light'} onClick={() => setTrailerAudioOverride((current) => !current)}>
              <FontAwesomeIcon icon={trailerAudioOverride ? faVolumeUp : faVolumeMute} fixedWidth={true} />
            </Button>
          )}*/}
              {!!keepWatching && (
                <div className={'keep-watching-wrapper ' + (isMobile ? 'prevent-expand' : '')}>
                  <span className={'keep-watching-progress-bar'} style={{ width: `${Math.max(video.percentage, 5)}%` }} />
                </div>
              )}

              <ButtonToolbar className={'mt-3 video-overlay-actions ' + (keepWatching ? 'keep-watching' : '')}>
                {!video.comingSoon && (
                  <ButtonGroup size={'sm'}>
                    <Button
                      disabled={isVideoLocked(video) && (keepWatching || !seriesPartOf.length)}
                      variant={'light'}
                      onClick={(event) => {
                        event.stopPropagation();
                        const doesAdvExist = isWithinInterval(new Date(), {
                          start: new Date(staticData.VIDEO_ADV_START._seconds * 1000),
                          end: new Date(staticData.VIDEO_ADV_END._seconds * 1000),
                        });
                        if (doesAdvExist && !userDataFmt.hasAlreadySeenAd) {
                          setIsAdvModalOpen(true);
                          return;
                        }
                        if (keepWatching || !seriesPartOf.length) {
                          trackVideoRecommendation(database, fv, currentUser, video).catch(console.error);
                          history.push(`/watch/${video.parent || video.id}`);
                        } else {
                          if (video.seriesId) history.push(`/series/${video.seriesId}?hl=${video.id}&rec=1`);
                          else if (seriesPartOf.length > 1) setSeriesSelectModal(true);
                          else history.push(`/series/${seriesPartOf[0].id}?hl=${video.id}`);
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={keepWatching || !seriesPartOf.length ? faPlay : faBookMedical} fixedWidth={true} />
                    </Button>
                    <MyListButton videoId={video.id} inline={true} />
                    <VideoRating video={video} inline={true} />
                  </ButtonGroup>
                )}

                <ButtonGroup className={'ml-auto'} size={'sm'}>
                  <Button
                    variant={'outline-light'}
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsDetailsModalOpen(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} fixedWidth={true} />
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </div>
          </>
        )}
      </div>

      {isDetailsModalOpen && (
        <Modal
          className={'dark-modal dark-modal-video'}
          size={'lg'}
          scrollable={true}
          show={isDetailsModalOpen}
          onHide={() => setIsDetailsModalOpen(false)}
          centered={isNative}
        >
          <div className={'video-wrapper'}>
            <Video
              src={video.trailer}
              preload={'all'}
              autoPlay={true}
              muted={!detailsTrailerAudio}
              style={{ width: '100%' }}
              poster={video.thumbnail}
              innerRef={modalVideoElementRef}
              onEnded={() => {
                modalVideoElementRef.current.currentTime = 0;
                modalVideoElementRef.current.src = null;
                modalVideoElementRef.current.autoplay = false;
                modalVideoElementRef.current.preload = 'metadata';
                modalVideoElementRef.current.src = video.trailer;
                setDetailsTrailerPlay(false);
              }}
            />
            <Button variant={'dark'} className={'close-btn'} onClick={() => setIsDetailsModalOpen(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
            {!!video.trailer && (
              <div className={'video-actions'}>
                <ButtonGroup>
                  <Button variant={'outline-light'} onClick={() => setDetailsTrailerPlay((current) => !current)}>
                    <FontAwesomeIcon icon={detailsTrailerPlay ? faPause : faPlay} fixedWidth={true} />
                  </Button>
                  <Button variant={'outline-light'} onClick={() => setDetailsTrailerAudio((curr) => !curr)}>
                    <FontAwesomeIcon icon={detailsTrailerAudio ? faVolumeUp : faVolumeMute} fixedWidth={true} />
                  </Button>
                </ButtonGroup>
              </div>
            )}
          </div>
          {!!video.logo ? <img src={video.logo} alt={video.title} className={'modal-video-logo'} /> : <h4 className={'modal-video-title'}>{video.title}</h4>}
          <Modal.Body>
            <div className={'d-flex align-items-start flex-column'}>
              <div className={'flex-grow-1 mb-3'}>
                {!!video.description && (
                  <div>
                    {video.description.map((segment, i) => (
                      <div key={i.toString()}>{segment}</div>
                    ))}
                  </div>
                )}
              </div>
              <div className={'d-flex justify-content-between w-100 flex-column flex-sm-row'}>
                {!!video.author && (
                  <div className={'video-author mb-2'}>
                    {!!video.author.photo && (
                      <div className={'author-photo'}>
                        <img src={video.author.photo} alt={video.author.name} />
                      </div>
                    )}
                    <div className={'author-data'}>
                      <div className={'author-name'}>{video.author.name}</div>
                      {!!video.author.description && <small className={'author-description'}>{video.author.description}</small>}
                    </div>
                  </div>
                )}
                <div>
                  {!!video.confidence && (
                    <div style={{ whiteSpace: 'nowrap' }} className={'mb-1'}>
                      {!!video.recommended && (
                        <Badge
                          variant={'warning'}
                          pill
                          className={!!staticData.SHOW_VIDEO_CONFIDENCE && !isNaN(+video.confidence) ? 'badge-pill-only-left' : ''}
                        >
                          <FontAwesomeIcon icon={faLightbulb} size={'sm'} className={'mr-1'} />
                          <span>{t('labels.videoConsigliato')}</span>
                        </Badge>
                      )}
                      {!!staticData.SHOW_VIDEO_CONFIDENCE && !isNaN(+video.confidence) && (
                        <Badge variant={'dark'} pill className={'text-success ' + (!!video.recommended ? 'badge-pill-only-right' : '')}>
                          {Math.floor(video.confidence * 100)}% {!video.recommended && <>Match</>}
                        </Badge>
                      )}
                    </div>
                  )}
                  {!!video.duration && (
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <span className={'text-muted'}>{t('labels.durata')}:</span> {parseDuration(video.duration)}
                    </div>
                  )}
                  {!!video.created && !video.expectedDate && (
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <span className={'text-muted'}>{t('labels.dataUscita')}:</span> {formatSecondsTimestamp(video.created._seconds, 'dd/MM/y')}
                    </div>
                  )}
                  {!!video.expectedDate && (
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <span className={'text-muted'}>{t('labels.dataUscitaPrevista')}:</span> {formatSecondsTimestamp(video.expectedDate._seconds, 'dd/MM/y')}
                    </div>
                  )}
                  {!!video.languages && (
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <span className={'text-muted'}>Audio:</span>{' '}
                      {video.languages.map((lang) => (
                        <CountryFlag language={lang} key={lang} withTooltip={true} className={'mr-1'} />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!!categories.length && (
              <div className={'tags'}>
                <FontAwesomeIcon color={'#e0ccb8'} className={'mr-2'} icon={faFolderOpen} fixedWidth={true} />
                {categories.map((tag) => (
                  <span
                    className={'tag category'}
                    key={tag.id}
                    onClick={async () => {
                      history.push(`/search?q=${tag.label}`);
                    }}
                  >
                    {tag.label}
                  </span>
                ))}
              </div>
            )}
            {!!tags.length && (
              <div className={'tags'}>
                <FontAwesomeIcon color={'#b7cce0'} className={'mr-2'} icon={faTags} fixedWidth={true} />
                {tags.map((tag) => (
                  <span
                    className={'tag'}
                    key={tag.id}
                    onClick={async () => {
                      history.push(`/search?q=${tag.label}`);
                    }}
                  >
                    {tag.label}
                  </span>
                ))}
              </div>
            )}
            {!!video.sponsored && staticData.SHOW_SPONSORS && (
              <div className={'mt-4 d-flex align-items-center'}>
                <FontAwesomeIcon icon={faAd} className={'text-warning mr-1'} />
                {video.sponsors.map(({ ref }) => (
                  <Badge
                    key={ref.id}
                    variant={'dark'}
                    className={'mr-1'}
                    style={{ cursor: !!ref.url ? 'pointer' : 'default' }}
                    onClick={() => !!ref.url && window.open(ref.url, '_blank')}
                  >
                    {ref.name}
                    {!!ref.url && <FontAwesomeIcon icon={faExternalLinkAlt} className={'text-secondary ml-1'} />}
                  </Badge>
                ))}
              </div>
            )}
          </Modal.Body>
          {!video.comingSoon && (
            <Modal.Footer>
              <div className={'mr-auto d-flex align-items-center'}>
                <MyListButton videoId={video.id} className={'mr-2'} />
                <VideoRating video={video} />
                {keepWatching && (
                  <Button
                    variant={'outline-light'}
                    className={'ml-1'}
                    disabled={removeKeepWatchingLoading}
                    onClick={() => {
                      setRemoveKeepWatchingLoading(true);
                      database
                        .doc(`userData/${currentUser.uid}/videos/${video.id}`)
                        .set({ hidden: true }, { merge: true })
                        .then(() => {
                          setRemoveKeepWatchingLoading(false);
                          setIsDetailsModalOpen(false);
                          onKeepWatchingRemove(video.id);
                        })
                        .catch(console.error);
                    }}
                  >
                    {t('videoCard.labels.rimuoviDaContinua')}
                  </Button>
                )}
              </div>
              {!!seriesPartOf.length && (
                <Button
                  variant={'info'}
                  onClick={() => {
                    if (video.seriesId) history.push(`/series/${video.seriesId}?hl=${video.id}`);
                    else if (seriesPartOf.length > 1) setSeriesSelectModal(true);
                    else history.push(`/series/${seriesPartOf[0].id}?hl=${video.id}`);
                  }}
                >
                  <FontAwesomeIcon className={'mr-2'} icon={faBookMedical} />
                  <span>{t('videoCard.labels.vediSerie')}</span>
                </Button>
              )}
              {!seriesPartOf.length && !video.comingSoon && (
                <>
                  {isVideoLocked(video) ? (
                    <SubscribeButton />
                  ) : (
                    <Button
                      variant={'primary'}
                      onClick={() => {
                        const doesAdvExist = isWithinInterval(new Date(), {
                          start: new Date(staticData.VIDEO_ADV_START._seconds * 1000),
                          end: new Date(staticData.VIDEO_ADV_END._seconds * 1000),
                        });
                        if (doesAdvExist && !userDataFmt.hasAlreadySeenAd) {
                          setIsAdvModalOpen(true);
                          return;
                        }
                        trackVideoRecommendation(database, fv, currentUser, video).catch(console.error);
                        history.push(`/watch/${video.parent || video.id}`);
                      }}
                    >
                      <FontAwesomeIcon className={'mr-2'} icon={faPlayCircle} />
                      <span>{t('labels.riproduci')}</span>
                    </Button>
                  )}
                </>
              )}
            </Modal.Footer>
          )}
        </Modal>
      )}

      {isAdvModalOpen && (
        <Modal
          className={'dark-modal dark-modal-video'}
          size={'lg'}
          scrollable={false}
          show={isAdvModalOpen}
          backdrop={'static'}
          onHide={() => setIsAdvModalOpen(false)}
          centered={isNative}
          onEscapeKeyDown={() => {
            return;
          }}
        >
          <div>
            <Video
              src={staticData.VIDEO_ADV_SRC}
              preload={'all'}
              autoPlay={true}
              controls={true}
              style={{ width: '100%' }}
              onEnded={async () => {
                await updateUserData({ hasAlreadySeenAd: true });
                history.push(`/watch/${video.parent || video.id}`);
              }}
            />
          </div>
          <Modal.Footer className="justify-content-center">
            Il messaggio promozionale sta per finire. Appena terminato, verrai portato al video scelto
          </Modal.Footer>
        </Modal>
      )}

      {seriesPartOf.length > 1 && (
        <Modal className={'dark-modal'} show={seriesSelectModal} onHide={() => setSeriesSelectModal(false)}>
          <Modal.Header closeButton style={{ '--bg-url': `url(${video.sm_thumbnail || video.thumbnail})` } as any} className={'series-select-header pb-0'}>
            <Modal.Title>{t('videoCard.labels.videoMultiSerie')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('videoCard.labels.scegliSerie')}</Modal.Body>
          <ListGroup className={'transparent-list clickable flex-grow-1'}>
            {seriesPartOf.map((serie) => {
              return (
                <ListGroup.Item
                  key={serie.id}
                  onClick={() => {
                    history.push(`/series/${serie.id}?hl=${video.id}`);
                  }}
                  style={{ borderTop: '1px solid rgba(0, 0, 0, 0.5)' }}
                  className={'px-3'}
                >
                  <div className={'d-flex align-items-center'}>
                    <span className={'mr-auto'}>{serie.name}</span>
                    <span className={'small text-muted ml-2'} style={{ whiteSpace: 'nowrap' }}>
                      {t('videoCard.labels.episodi')} {serie.seasons.map((s) => s.episodes.length).reduce((acc, val) => acc + val, 0)}
                    </span>
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Modal>
      )}
    </>
  );
};

export { VideoCardComponent as VideoCard };
